import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { BillingAccountInvoice } from "@warrenio/api-spec/spec.oats.gen";
import { ShortDate } from "../../../components/l10n/DateFormat.tsx";

/** NB: Always and only use this component to display invoice dates! */

export function InvoicePeriod({ invoice }: { invoice: BillingAccountInvoice }) {
    return (
        <>
            <ShortDate date={apiUnixToDate(invoice.period_start)} forceUtc /> -{" "}
            <ShortDate date={apiUnixToDate(invoice.period_end)} forceUtc />
        </>
    );
}
export function InvoiceDueDate({ invoice }: { invoice: BillingAccountInvoice }) {
    return invoice.due_date ? <ShortDate date={apiUnixToDate(invoice.due_date)} forceUtc /> : "-";
}

export function InvoiceIssueDate({ invoice }: { invoice: BillingAccountInvoice }) {
    // TODO: Should invoice issue date use UTC or not?
    return <ShortDate date={apiUnixToDate(invoice.created)} forceUtc />;
}
