import { createLazyFileRoute } from "@tanstack/react-router";
import type { InvoiceId, InvoiceTypes } from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceView } from "../../../../modules/billing/invoice/InvoiceView.tsx";

export const Route = createLazyFileRoute("/_main/billing/account/$id/detail/$type/$invoiceId")({
    component: Component,
});

function Component() {
    const { id, invoiceId, type } = Route.useParams();

    // TODO: Validate the type
    const parsedType = type as InvoiceTypes;
    const baId = Number(id);
    const parsedInvoiceId: InvoiceId = invoiceId === "current" ? "current" : Number(invoiceId);
    console.log(parsedType);
    return <InvoiceView baId={baId} invoiceId={parsedInvoiceId} />;
}
