import type { InvoiceStatus } from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { Badge } from "../../components/Badge.tsx";

export function ReportStatus({ status }: { status: InvoiceStatus }) {
    switch (status) {
        case InvoiceStatusEnum.PAID:
            return <Badge color="success">Paid</Badge>;

        case InvoiceStatusEnum.UNPAID:
            return <Badge color="error">Unpaid</Badge>;

        case InvoiceStatusEnum.IN_PROGRESS:
            return <Badge color="success">Ongoing</Badge>;

        default:
            exhaustiveSwitchCheck(status);
    }
}
