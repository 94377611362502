import type {
    BillingAccount,
    BillingAccountInvoice,
    BillingAccountUsage,
    InvoiceRecord,
} from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";

const fakeRecordTag = Symbol.for("usageToRecord/fakeRecord");

function usageToRecord(usage: BillingAccountUsage, index: number): InvoiceRecord {
    const { description, cost, price, hours, price_unit, _technical } = usage;
    return {
        [fakeRecordTag as any]: true,

        amount: cost,
        created: new Date().getTime(),
        id: -index,
        invoice_id: -1,
        is_technical: _technical,
        item_price: price,
        name: description,
        qty_unit: price_unit,
        qty: hours,
        vat_amount: -0,
    };
}

export function createCurrentUsage(
    usage: BillingAccountUsage[],
    billingAccount: BillingAccount,
): BillingAccountInvoice {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const startDate = new Date(y, m, 1);
    const endDate = new Date(y, m + 1, 0);

    const { running_totals, vat_percentage, id, discount_percentage } = billingAccount;
    const { credit_amount, discount_amount, subtotal, total, total_before_tax, vat_tax } = running_totals;

    return {
        id: "current",
        type: "cloud_services",
        is_current: true,
        name: "Current Usage",
        records_list: usage.map(usageToRecord),
        period_start: startDate.getTime(),
        period_end: endDate.getTime(),
        created: date.getTime(),
        due_date: 0,
        status: InvoiceStatusEnum.IN_PROGRESS,
        vat_percentage,
        discount_percentage,
        totals: {
            credit: credit_amount,
            subtotal,
            total,
            total_before_tax,
            vat_tax,
            discount_amount,
            // TODO: waiting for BE amends?
            subtotal_resource_usage: 0,
        },
        billing_account_id: id,
    };
}
